<template>
  <div>
    <v-footer v-scroll="onScroll" padless color="white pt-2 pb-2">
      <v-container>
        <v-card flat tile width="1550" color="white">
          <div style="color:#1A237E" v-if="isFooterDesktop">
            <v-row>
              <v-col cols="12" sm="12" class="text-center">
                <!-- ติดต่อเรา -->
                <h3>{{$t('landingpagefooter.contactus')}}</h3>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" sm="3">
                <v-row>
                  <v-col sm="3" align="right" justify="right">
                    <v-icon color="indigo darken-4"
                      >mdi-city-variant-outline</v-icon
                    >
                  </v-col>
                  <v-col sm="9" align="left" justify="left">
                    <!-- ที่อยู่
                    <br />เลขที่ 1768 <br />ชั้น 10-12 และชั้น IT
                    <br />อาคารไทยซัมมิททาวเวอร์ <br />ถนนเพชรบุรีตัดใหม่
                    <br />แขวงบางกะปิ เขตห้วยขวาง กรุงเทพมหานคร 10310 -->
                    {{$t('landingpagefooter.addressheader')}}
                    <br />{{$t('landingpagefooter.addresscontent1')}} 
                    <br />{{$t('landingpagefooter.addresscontent2')}}
                    <br />{{$t('landingpagefooter.addresscontent3')}}
                    <br />{{$t('landingpagefooter.addresscontent4')}}
                    <br />{{$t('landingpagefooter.addresscontent5')}}
                          {{$t('landingpagefooter.addresscontent6')}}
                          {{$t('landingpagefooter.addresscontent7')}}
                          {{$t('landingpagefooter.addresscontent8')}}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="3">
                <v-row>
                  <v-col sm="3" align="right" justify="right">
                    <v-icon color="indigo darken-4">mdi-web</v-icon>
                  </v-col>
                  <v-col sm="9" align="left" justify="left">
                    Website
                    <br />box.one.th
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="3">
                <v-row>
                  <v-col sm="3" align="right" justify="right">
                    <v-icon color="indigo darken-4">mdi-email-outline</v-icon>
                  </v-col>
                  <v-col sm="9" align="left" justify="left">
                    Email
                    <br />nockkc@inet.co.th
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="3">
                <v-row>
                  <v-col sm="3" align="right" justify="right">
                    <v-icon color="indigo darken-4">mdi-phone-outline</v-icon>
                  </v-col>
                  <v-col sm="9" align="left" justify="left">
                    <!-- เบอร์ติดต่อ -->
                    {{$t('landingpagefooter.telephone')}}
                    <br />02-257-7111
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>

          <div style="color:#1A237E" v-if="isFooterMobile">
            <v-row>
              <v-col cols="12" sm="12" class="text-center">
                <h3>
                  <!-- ติดต่อเรา -->
                  {{$t('landingpagefooter.contactus')}}
                </h3>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" sm="1" align="center" justify="center">
                <v-icon color="indigo darken-4"
                  >mdi-city-variant-outline</v-icon
                >
              </v-col>

              <v-col cols="12" sm="2" align="center" justify="center">
                <!-- ที่อยู่
                <br />เลขที่ 1768 ชั้น 10-12 <br />และชั้น IT
                อาคารไทยซัมมิททาวเวอร์ <br />ถนนเพชรบุรีตัดใหม่ แขวงบางกะปิ
                <br />เขตห้วยขวาง กรุงเทพมหานคร 10310 -->
                {{$t('landingpagefooter.addressheader')}}
                    <br />{{$t('landingpagefooter.addresscontent1')}} 
                    <br />{{$t('landingpagefooter.addresscontent2')}}
                    <br />{{$t('landingpagefooter.addresscontent3')}}
                    <br />{{$t('landingpagefooter.addresscontent4')}}
                    <br />{{$t('landingpagefooter.addresscontent5')}}
                          {{$t('landingpagefooter.addresscontent6')}}
                    <br />{{$t('landingpagefooter.addresscontent7')}}
                          {{$t('landingpagefooter.addresscontent8')}}
              </v-col>
              <!-- <v-col cols="12" sm="1" align="center" justify="center">
                <v-icon color="indigo darken-4"
                  >mdi-clock-time-nine-outline</v-icon
                >
              </v-col>
              <v-col cols="12" sm="2" align="center" justify="center">
                วันเวลาทำการ
                <br />วันจันทร์ - ศุกร์ <br />เวลา 09.00 - 17.00 น.
              </v-col> -->
              <v-col cols="12" sm="1" align="center" justify="center">
                <v-icon color="indigo darken-4">mdi-web</v-icon>
              </v-col>
              <v-col cols="12" sm="2" align="center" justify="center">
                Website
                <br />box.one.th
              </v-col>
              <v-col cols="12" sm="1" align="center" justify="center">
                <v-icon color="indigo darken-4">mdi-email-outline</v-icon>
              </v-col>
              <v-col cols="12" sm="2" align="center" justify="center">
                Email
                <br />nockkc@inet.co.th
                <!-- <br />DPO-inet@inet.co.th -->
              </v-col>
              <v-col cols="12" sm="1" align="center" justify="center">
                <v-icon color="indigo darken-4">mdi-phone-outline</v-icon>
              </v-col>
              <v-col cols="12" sm="2" align="center" justify="center">
                {{$t('landingpagefooter.telephone')}}
                <br />02-257-7111
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
export default {
  data: () => ({
    isFooterDesktop: true,
    isFooterMobile: false
  }),
  methods: {
    onScroll() {
      const showsize = window.innerWidth;
      this.isFooterDesktop = showsize > 600;
      this.isFooterMobile = showsize < 600;
    }
  }
};
</script>
